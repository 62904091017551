import { useTranslation } from "react-i18next";
import { Regions } from "../../helpers/constants";

export function BannerDropDown({
    setValue,
    setShowBanners,
    clearErrors,
    setBanner,
    setRegion,
    isSwipe,
}: {
    setBanner?: any;
    setValue?: any;
    setShowBanners: any;
    clearErrors?: any;
    setRegion?: any;
    isSwipe?: boolean;
}) {
    const { t } = useTranslation();
    return (
        <div className="wrapperBannerList">
            {Regions.map(({ name, value, banners }, j) => {
                return (
                    <ul className="bannerList" key={j}>
                        <li>
                            <p style={{ marginBottom: "0" }}>{name === "Quebec" ? t("signup.Quebec") : name}</p>
                            <ul>
                                {banners.map((banner, i) => (
                                    <li
                                        key={i}
                                        value={value}
                                        className="bannerItem"
                                        onClick={() => {
                                            isSwipe ? setBanner(banner) : setValue("banner", banner);
                                            isSwipe ? setRegion(value) : setValue("region", value);

                                            setShowBanners(false);
                                            !isSwipe && clearErrors("banner");
                                        }}>
                                        <span className="listDecor">-</span>
                                        {banner}
                                    </li>
                                ))}
                                <li
                                    className="bannerItem"
                                    value="backstage"
                                    onClick={() => {
                                        isSwipe ? setBanner("backstage") : setValue("banner", "backstage");
                                        isSwipe ? setRegion(value) : setValue("region", value);

                                        setValue("storeNumber", "0000");

                                        setShowBanners(false);
                                        !isSwipe && clearErrors("banner");
                                    }}>
                                    <span className="listDecor">-</span>
                                    {t("signup.backstage")}
                                </li>
                            </ul>
                        </li>
                    </ul>
                );
            })}
        </div>
    );
}

export default BannerDropDown;
